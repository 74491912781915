/* eslint-disable */
import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import RenderBlock from '../../components/blocks/RenderBlock'
import BlueHeader from '../../components/common/BlueHeader'
import Container from '../../components/common/Container'
import Offers from '../../components/common/Offers'

const Rheppdonationoffer = ({ data }) => {
  let urls = data.defaultPage.urls
  urls.fr = "/OffreavecdonProjetunabripourchaqueami"
  return (
    <Layout
      meta={data.defaultPage.meta}
      urls={urls}
      locale='en'
    >
      <Header
        urls={data.defaultPage.urls}
        locale='en'
      />

      <BlueHeader title={data.defaultPage.title} />

      <main>
        <Container width={830}>
          <RenderBlock blocks={data.defaultPage.blocks} />
        </Container>
      </main>

      <Footer />
      <Offers />
    </Layout>
  )
}



export const query = graphql`
query RHEPPDonationOffer {
  defaultPage(guid: { eq: "62ce2f91-552e-4724-92fd-99be710e2efc" }, locale: {eq:"en"}) {
    title
    subtitle
    description
    blocks {
      __typename
      ...textBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default Rheppdonationoffer
